import { styled } from '@mui/system'

const BottomAreaWrapper = styled('div')`
	background-color: ${({ theme }) => theme.palette.footer.bgBottom};
	display: flex;
	align-items: center;
	justify-content: center;
	padding: ${({ theme }) => theme.spacing(3.5, 3)};
	a {
		color: ${({ theme }) => theme.palette.footer.color};
	}
	${({ theme }) => theme.breakpoints.down('md')} {
		border-top: 1px solid ${({ theme }) => theme.palette.footer.border};
	}
	${({ theme }) => theme.breakpoints.up('md')} {
		padding: ${({ theme }) => theme.spacing(3.5, 5)};
		justify-content: flex-start;
	}
`

export { BottomAreaWrapper }
