import { styled } from '@mui/system'
import { ChangeCountryLanguage } from '../../change-country-language'

const MiddleBottomWrapper = styled('div')`
	background-color: ${({ theme }) => theme.palette.footer.bgMiddleBottom};
	color: ${({ theme }) => theme.palette.footer.color};
	display: flex;
	flex-wrap: wrap;
	${({ theme }) => theme.breakpoints.up('md')} {
		padding: ${({ theme }) => theme.spacing(1.75, 5)};
	}
	.BHFooterSocialLinks {
		width: 100%;
		flex-grow: 1;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 13px 0;
		${({ theme }) => theme.breakpoints.up('md')} {
			width: auto;
			justify-content: flex-end;
			padding: 0;
		}
		.BHSocialWrapper {
			& a {
				margin: 0;
				padding: 16px;
			}
		}
	}
	.footer-storelocator-link {
		width: 50%;
		justify-content: center;
		padding: 30px 0;
		border-bottom: 1px solid ${({ theme }) => theme.palette.footer.border};
		border-radius: 0;
		&:hover,
		&:active {
			border-bottom: 1px solid ${({ theme }) => theme.palette.footer.border};
		}
		${({ theme }) => theme.breakpoints.up('md')} {
			width: auto;
			border-bottom: none;
			padding: 0;
			&:hover,
			&:active {
				border-bottom: none;
			}
		}
	}
`

const ChangeCountryLanguageStyled = styled(ChangeCountryLanguage)`
	&.change-country-language-trigger-footer {
		flex-grow: 1;
		justify-content: center;
		border-bottom: 1px solid ${({ theme }) => theme.palette.footer.border};
		padding: 30px 0;
		${({ theme }) => theme.breakpoints.up('md')} {
			flex-grow: 0;
			margin-right: 60px;
			width: auto;
			border-bottom: none;
			padding: 0;
		}
	}
`

export { ChangeCountryLanguageStyled, MiddleBottomWrapper }
