import { cx } from '@emotion/css'
import SocialLinks from '../social-links'
import { ChangeCountryLanguageStyled, MiddleBottomWrapper } from './middle-bottom-area.styled'

const MiddleBottomArea = () => {
	return (
		<MiddleBottomWrapper className={cx('MiddleBottomArea-root')}>
			<ChangeCountryLanguageStyled className="change-country-language-trigger-footer" />

			<SocialLinks
				buttonProps={{ color: 'primary', component: 'a', variant: 'icon' }}
				className="BHFooterSocialLinks"
				iconProps={{ fontSize: 'middle' }}
			/>
		</MiddleBottomWrapper>
	)
}

export default MiddleBottomArea
